import sponser1 from "../../assets/client-1.png";
import sponser2 from "../../assets/client-2.png";
import sponser3 from "../../assets/client-3.png";
import sponser4 from "../../assets/client-4.png";
import sponser5 from "../../assets/client-5.png";
import sponser6 from "../../assets/client-6.png";
export const paragraphs = [
  "Ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  "Duis aute irure dolor in reprehenderit in voluptate velit.",
  "Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.",
];
export const cards = [
  {
    title: "Misión",
    text: "Ofrecer soluciones tecnológicas innovadoras en meteorología y software en la nube, brindando herramientas precisas y eficientes para la toma de decisiones en tiempo real.",
    number: "01",
  },
  {
    title: "Visión",
    text: "Ser líderes en soluciones tecnológicas integradas para la industria meteorológica y servicios en la nube, reconocidos por nuestra innovación y calidad en Perú y Latinoamérica.",
    number: "02",
  },
  {
    title: "Objetivos",
    //text: "Molestiae officiis omnis illo asperiores. Aut doloribus vitae sunt debitis quo vel nam quis",
    text: "1. Desarrollar plataformas de monitoreo meteorológico en tiempo real.\n2. Ampliar servicios en la nube con soluciones personalizadas.\n3. Formar alianzas estratégicas con instituciones y empresas del sector.",    number: "03",
  },
];
export const sponsors = [
  sponser1,
  sponser2,
  sponser3,
  sponser4,
  sponser5,
  sponser6,
];
